import * as React from "react";
import { ChakraProvider, Box, Flex, Heading } from "@chakra-ui/react";
import NoteList from "./components/NoteList";
import TagList from "./components/TagList";
import ContactUs from "./components/ContactUs";
import Legend from "./components/Legend";
import customTheme from "./theme";
import Intro from "./components/Intro";

interface Note {
  title: string;
  items: { text: string; link?: string; comment: string; visited: boolean }[];
  tags: string[];
}

export const App = () => {
  const [notes, setNotes] = React.useState<Note[]>([]);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);

  React.useEffect(() => {
    // Fetch notes data from JSON file
    fetch("/notes.json")
      .then((response) => response.json())
      .then((data) => setNotes(data));
  }, []);

  const allTags = React.useMemo(() => {
    const tags = notes.flatMap((note) => note.tags);
    return Array.from(new Set(tags));
  }, [notes]);

  const filteredNotes = React.useMemo(() => {
    if (selectedTags.length === 0) return notes;
    return notes.filter((note) =>
      selectedTags.every((tag) => note.tags.includes(tag))
    );
  }, [notes, selectedTags]);

  const handleTagClick = (tag: string) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag)
        ? prevSelectedTags.filter((t) => t !== tag)
        : [...prevSelectedTags, tag]
    );
  };
  return (
    <ChakraProvider theme={customTheme}>
      <Flex direction="column" height="100vh">
        <Box bg="red.400" color="white" p={4}>
          <Heading as="h1" size="lg">
            הרשימות של מיכל
          </Heading>
        </Box>
        <Box
          p={4}
          width="60%"
          minWidth="300px"
          mx="auto"
          justifyContent="center"
          alignItems="center"
        >
          <Intro />
          <Legend/>
        </Box>
        <Box p={2}>
          <TagList
            tags={allTags}
            selectedTags={selectedTags}
            onTagClick={handleTagClick}
          />
          <NoteList notes={filteredNotes} />
        </Box>
        <Box borderBottom="1px" borderColor="gray.200" my={6} />
        <Box p={4}>
          <ContactUs />
        </Box>
      </Flex>
    </ChakraProvider>
  );
};
