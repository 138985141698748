import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

const Intro: React.FC = () => {
  return (
    <Box mb={8} p={4} border="1px" borderColor="gray.200" borderRadius="md">
      <Heading as="h2" size="lg" mb={4}>
        ברוכים הבאים!{" "}
      </Heading>
      <Text fontSize="md">
        שמי מיכל ואני אמא, בת זוג, הייטקיסטית, גרה בגבעתיים ואוהבת מאוד רשימות.
      </Text>
      <Text fontSize="md">
      באתר זה תוכלו
        למצוא מגוון רשימות בתחומים שונים של החיים שריכזתי לאורך השנים. לא בכל
        המקומות ביקרתי או ניסיתי ולפי המקרא תוכלו לראות האם אני ממליצה אישית או
        שפשוט שמעתי על המקום ורציתי לשתף. אני מקווה שתמצאו את האתר מועיל
        ושימושי.
        </Text>
        <Text fontSize="md">
        אם יש לכם שאלות או הצעות, אל תהססו ליצור קשר!

            </Text>
    </Box>
  );
};

export default Intro;
