import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import Note from "./Note";

const LIST_SIZE = 24;

interface INote {
  title: string;
  items: { text: string; link?: string; comment: string; visited: boolean }[];
  tags: string[];
}
interface NoteListProps {
  notes: INote[];
}

const NoteList: React.FC<NoteListProps> = ({ notes }) => {
  const displayedNotes = notes.slice(0, LIST_SIZE);
  const hasMoreNotes = notes.length > LIST_SIZE;

  return (
    <Box className="note-list" maxWidth="80vw" mx="auto" p={4}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
        {displayedNotes.map((note, index) => (
          <Note
            key={index}
            title={note.title}
            items={note.items}
            tags={note.tags}
          />
        ))}
      </SimpleGrid>
      {hasMoreNotes && (
        <Box textAlign="center" mt={4}>
          <Text fontSize="xl" color="gray.500">
            ...
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default NoteList;
