import React from "react";
import { Box, Flex, Text, Icon } from "@chakra-ui/react";
import { CheckCircleIcon, InfoIcon } from "@chakra-ui/icons";

const Legend: React.FC = () => {
  return (
    <Box
      p={4}
      border="1px"
      borderColor="gray.200"
      borderRadius="md"
      mb={4}
    >
      <Text fontSize="lg" fontWeight="bold" mb={2}>
        מקרא
      </Text>
      <Flex alignItems="center" mb={2}>
        <Icon as={CheckCircleIcon} color="green.500" mr={2}  />
        <Text mr={2}>ניסיתי/ביקרתי</Text>
      </Flex>
      <Flex alignItems="center">
        <Icon as={InfoIcon} mr={2} />
        <Text mr={2}>מידע נוסף בהערה</Text>
      </Flex>
    </Box>
  );
};

export default Legend;
