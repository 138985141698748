import React, { useState } from "react";
import {
  Box,
  Text,
  Input,
  Flex,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  useToast,
  useMediaQuery,
} from "@chakra-ui/react";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

const EmailJS_SERVICE_ID = "service_0ooq03d";
const EmailJS_TEMPLATE_ID = "template_jv5fxyu";
const EMAILJS_PUBLIC_KEY = "bkUrbIaV2ffdQgufD";
const CAPTCHA_SITE_KEY = "6Lfq1lgqAAAAAJFUxRGyMX6o-VZEEvL6MGDhNVxV";


const ContactUs: React.FC = () => {
    const [captchaValue, setCaptchaValue] = useState<string | null>(null);
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const toast = useToast(); // Initialize toast

    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        if (!captchaValue) {
          toast({
            title: "CAPTCHA לא מאומתת",
            description: "אנא מלא את ה- CAPTCHA.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
    
        emailjs
          .sendForm(
            EmailJS_SERVICE_ID,
            EmailJS_TEMPLATE_ID,
            e.currentTarget,
            EMAILJS_PUBLIC_KEY
          )
          .then(
            (result) => {
              toast({
                title: "ההודעה נשלחה בהצלחה.",
                description: "קיבלנו את הודעתך ונחזור אליך בהקדם האפשרי.",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
            },
            (error) => {
              toast({
                title: "שגיאה",
                description: "שגיאה התרחשה בזמן שליחת הודעתך. אנא נסה שוב.",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            }
          );
    
        e.currentTarget.reset();
        setCaptchaValue(null); // Reset CAPTCHA
      };
    
      const onCaptchaChange = (value: string | null) => {
        setCaptchaValue(value);
      };
    
  return (
    <Box mt={8}>
      <Flex mb={4} justifyContent="center" alignItems="center">
        <Box mb={4} flex="1" minWidth="60px" maxWidth="60%">
          <Text fontSize="2xl" mb={4}>
            צור קשר
          </Text>
          <form onSubmit={sendEmail}>
            <FormControl id="from_name" mb={4}>
              <FormLabel>שם</FormLabel>
              <Input type="text" name="from_name" required />
            </FormControl>
            <FormControl id="email" mb={4}>
              <FormLabel>אימייל</FormLabel>
              <Input type="email" name="email" required />
            </FormControl>
            <FormControl id="message" mb={4}>
              <FormLabel>הודעה</FormLabel>
              <Textarea name="message" required />
            </FormControl>
            <ReCAPTCHA
              sitekey={CAPTCHA_SITE_KEY!}
              onChange={onCaptchaChange}
              size={isMobile ? "compact" : "normal"}
            />
            <Button type="submit" colorScheme="red">
              שלח
            </Button>
          </form>
        </Box>
      </Flex>
    </Box>
  );
};

export default ContactUs;
