import React from "react";
import { Badge, Box, Flex, Wrap, WrapItem } from "@chakra-ui/react";

interface TagListProps {
  tags: string[];
  selectedTags: string[];
  onTagClick: (tag: string) => void;
}

const TagList: React.FC<TagListProps> = ({
  tags,
  selectedTags,
  onTagClick,
}) => {
  return (
    <Box mb={4}>
      <Flex justifyContent="center" alignItems="center">
        <Box mb={4} flex="1" minWidth="300px" maxWidth="60%">
          <Wrap justify="center">
            {tags.map((tag, index) => (
              <WrapItem key={index}>
                <Badge
                  cursor="pointer"
                  colorScheme={selectedTags.includes(tag) ? "red" : "gray"}
                  onClick={() => onTagClick(tag)}
                  variant={selectedTags.includes(tag) ? "solid" : "outline"}
                  p={2}
                  borderRadius="md"
                >
                  {tag}
                </Badge>
              </WrapItem>
            ))}
          </Wrap>
        </Box>
      </Flex>
    </Box>
  );
};

export default TagList;
