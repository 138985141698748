import React from "react";
import {
  Box,
  Heading,
  Text,
  Badge,
  VStack,
  Link,
  Popover,
  PopoverTrigger,
  IconButton,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { CheckCircleIcon, InfoIcon } from "@chakra-ui/icons";

interface NoteProps {
  title: string;
  items: { text: string; link?: string; comment: string; visited: boolean }[];
  tags: string[];
}

const Note: React.FC<NoteProps> = ({ title, items, tags }) => {
  return (
    <Box
      className="note"
      p={4}
      borderWidth="2px"
      borderRadius="lg"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box>
        <Heading as="h2" size="lg" mb={4}>
          {title}
        </Heading>
        <VStack align="start" spacing={1} mb={4}>
          {items.map((item, index) => (
            <HStack key={index} align="center" spacing={2}>
              {item.link ? (
                <Link href={item.link} isExternal>
                  {item.text}
                </Link>
              ) : (
                <Text>{item.text}</Text>
              )}
              {item.visited && (
                <Icon
                  as={CheckCircleIcon}
                  aria-label="More information"
                  mr={1}
                  p={0}
                  minW="auto" 
                  color="green.500"
                />
              )}
              {item.comment && (
                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      icon={<InfoIcon />}
                      aria-label="More information"
                      size="xs"
                      variant="ghost"
                      p={0} // Remove padding
                      minW="auto" // Remove minimum width
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Text>{item.comment}</Text>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </HStack>
          ))}
        </VStack>
      </Box>
      <Box mt="auto">
        {tags.map((tag, index) => (
          <Badge key={index} colorScheme="red" mr={1}>
            {tag}
          </Badge>
        ))}
      </Box>
    </Box>
  );
};

export default Note;
